import styled from "styled-components"
import { Column, Row } from "./defaults"
import { useDispatch, useSelector } from "react-redux";
import { updateRegistrationValue } from "../redux/reducer/registration";
import { MainInput } from "./input";

const RegisterTitle = styled.div`
    color: #4b4a4a;
    font-family: 'Satoshi-Bold';
    font-size: 40px;
    font-weight: 700;
    line-height: 51px;
    letter-spacing: -0.013em;
`;

const RegisterBarWrapper = styled(Row)`

    background: #F1F3F7;
    height: 8px;
    border-radius: 2px;
    overflow: hidden;
    width: 200px;
    
    div {
        background: #F9BF29;
        border-radius: 7px;
        overflow: hidden;
        height: 100%;
    }
`;

export const RegistrationInput = (props) => {

    const {
        id,
        disableTrim,
        numberOnly,
        subIds,
        nestedQuestion
    } = props

    
    const dispatch = useDispatch();

    const valueReducer = (state) => (prev, key) => ({...prev, [key]: state.registration[key]})

    const value = useSelector((state) => 
        subIds ? subIds.reduce(valueReducer(state), {}) :
        nestedQuestion?.id ? [nestedQuestion.id, id].reduce(valueReducer(state), {}) :
        state.registration[id]
    );

    const onChangePhone = (overrideId) => (e) => {
        const thisId = (overrideId) ? overrideId : id;
        dispatch(updateRegistrationValue({
            id: thisId, value: typeof e === 'string' ?
            e : e?.target?.value
        })
    )}

    const onChangePassword = (overrideId) => (e) => {
        const thisId = (overrideId) ? overrideId : id;
        dispatch(updateRegistrationValue({
            id: thisId, value: typeof e === 'string' ?
            e : e?.target?.value
        })
    )}



    const onChangeDefault = (overrideId) => (e) => {

        const thisId = (overrideId) ? overrideId : id;

        let nextValue = (typeof e === 'string' 
            || Array.isArray(e)
            || !!e.value) ?
        e : e?.target?.value;

        if (!disableTrim && typeof nextValue === 'string') {
            nextValue = nextValue.trim()
        }

        if (numberOnly && typeof nextValue === 'string') {
            nextValue = nextValue.replace(/[^0-9.]/g, '')
        }

        dispatch(updateRegistrationValue({ id: thisId, value: nextValue }))

    }
    

    const onChange = props.type === 'phone' ?
    onChangePhone : props.type === 'password' ?
    onChangePassword : onChangeDefault;

    return <MainInput
    value={value}
    onChange={onChange}
    {...props}
    />

}


export const RegistrationProgressBar = ({ percent, style = {} }) => {

    const flexLeft = percent;
    const flexRight = 100 - percent

    return <RegisterBarWrapper style={style}>
        <div style={{ flex: flexLeft }} />
        <div style={{
            background: 'transparent',
            flex: flexRight
        }}/>
    </RegisterBarWrapper>
}



export const RegistrationSectionTitle = ({ children, subtitle }) => {


    return <Column style={{ gap: 10 }}>
        <RegisterTitle>{children}</RegisterTitle>
    </Column>

}