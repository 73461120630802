import { combineReducers, configureStore } from '@reduxjs/toolkit';
import sessionStorage from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import registrationReducer from './reducer/registration';

// Configuration for session storage
const sessionPersistConfig = {
  key: 'registration',
  storage: sessionStorage,
};

const rootReducer = combineReducers({
  registration: persistReducer(sessionPersistConfig, registrationReducer),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export { store };