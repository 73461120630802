import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Column, Row } from '../components/defaults';
import checkIcon from '../images/check-big.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';

const CheckIcon = styled.img`
    height: 42px;
    width: 42px;
    margin-right: 16px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 26px;
    flex: 1;
    
    @media (max-width: 762px) {
        flex-direction: column;
        width: initial;
        padding: 24px;
    }
`;

const ContentsWrapper = styled(Column)`
    gap: 26px;
    max-width: 450px;
    margin-top: 27px;
    padding-bottom: 100px;

    @media (min-width: 768px) {
        margin: auto;
        margin-top: 67px;
    };
`;

const Headline = styled.div`
    font-size: 40px;
    letter-spacing: -0.013em;
    color: #4b4a4a;

    @media (max-width: 768px) {
        font-size: 36px;
        line-height: 40px;
    }
`;

const Disclaimer = styled.div`
    font-family: 'Satoshi-Medium';
    font-size: 18px;
    border-radius: 10px;
    line-height: 26px;
    letter-spacing: 0.007em;
    color: #4b4a4a;
    background: #F4F3FE;
    padding: 16px 24px;
    font-size: 18px;
`;

const InstructionWrapper = styled(Row)`
    margin-bottom: 40px;
`;

const InstructionStep = styled.div`
    font-family: 'Satoshi-Bold';
    font-size: 20.7977px;
    line-height: 28px;
    color: #4b4a4a;
    width: 42px
`;

const InstructionText = styled.div`
    font-family: 'Satoshi-Medium';
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.007em;
    color: #4b4a4a;
    flex: 1;
`;

const Instruction = ({ number, text }) => {

    return <InstructionWrapper>
        <InstructionStep>{number}</InstructionStep>
        <InstructionText>{text}</InstructionText>
    </InstructionWrapper>

}

const Instructions = styled.div`

    font-family: 'Satoshi-Bold';
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.013em;
    margin-top: 30px;
    color: #4b4a4a;
    align-self: stretch;
    margin-bottom: 25px;
`;

const TEXTS = [
    "Remove the unopened swabs from the clear plastic bag and place on a clean surface",
    "Remove one swab from its package and firmly scrape the inside of one cheek (while rotating the swab) for 45 seconds",
    "Open one vial, insert the swab (tip down), align the black line on the swab with the edge of the vial, and snap it off with the swab tip remaining in the vials liquid.",
    "On the opposite cheek, repeat steps 3 & 4 using the 2nd swab vial",
    "With the orange cap securely fastened on both vials, Insert both into the clear plastic bag and then seal the bag.",
    "Place the sealed plastic bag in the envelope provided. Seal the envelope and put into mail for return"
]

const SignInToDashboard = styled(Button)`
    white-space: nowrap;   
`;

const ContentsResultsAvailable = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    return <ContentsWrapper>
        <Row style={{ alignItems: 'center', marginBottom: 24}}>
            <CheckIcon src={checkIcon} />
            <Headline>Your Genetic Report is ready!</Headline>
        </Row>
        <Disclaimer>Please sign in to your dashboard below to check your Genetic Results and download your PDF report.</Disclaimer>
        <SignInToDashboard style={{ marginTop: 30 }} onClick={() => navigate('/sign-in')}>Sign in to Dashboard</SignInToDashboard>        
            
    </ContentsWrapper>

}


const ContentAgeReady = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    return <ContentsWrapper>
        <Row style={{ alignItems: 'center', marginBottom: 24}}>
            <CheckIcon src={checkIcon} />
            <Headline>Your Age Test Results are ready!</Headline>
        </Row>
        <Disclaimer>Please sign in to your dashboard below to check your Age Test Results.</Disclaimer>
        <SignInToDashboard style={{ marginTop: 30 }} onClick={() => navigate('/sign-in')}>Sign in to Dashboard</SignInToDashboard>        
            
    </ContentsWrapper>

}

const Contents = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <ContentsWrapper>
        <Row style={{ alignItems: 'center', marginBottom: 24}}>
            <CheckIcon src={checkIcon} />
            <Headline>Your kit is Registered</Headline>
        </Row>
        <Instructions>Ready, set, swab! Follow the directions to start!</Instructions>
        {TEXTS.map((text, i) => <Instruction
            text={text}
            number={(i + 1).toString().padStart(2, '0')} />)}

    </ContentsWrapper>

}


const VideoWrapper = styled.div`
    
    @media (max-width: 762px) {
        display: none;
    }

`;

const Video = () => {

    return <VideoWrapper>
        
    </VideoWrapper>
}   

export const KitSuccess = () => {
    
    const [searchParams] = useSearchParams();
    const reportAvailable = searchParams.get('report_available')
    const ageReady = searchParams.get('age_available')

    return <Column>
        <Container>
            {reportAvailable ? <ContentsResultsAvailable /> 
            : ageReady ? <ContentAgeReady />
            : <>
                <Contents />
                <Video />
            </> 
            }
        </Container>
    </Column>    
}