import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  barcode: '',
  first_name: '',
  last_name: '',
  email: '',
  date_of_birth: '',
  password: '',
  repeat_password: '',
  phone: '',
  registered_at: ''
}

export const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        clear: () => {
            return initialState
        },
        updateRegistrationValue: (state, action) => {
            state[action.payload.id] = action.payload.value
        }
    },
})

export const { clear, updateRegistrationValue } = registrationSlice.actions;

export default registrationSlice.reducer;
  