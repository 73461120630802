/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components"
import { RegistrationForm } from "./template";
import { isInvalidEmail, isInvalidPw } from "../utils/validator";
import api from "../utils/api";
import { accountInformation } from "../constants/registration";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const requiredFields = [
    'first_name',
    'last_name',
    'email',
    'password',
    'repeat_password',
    'date_of_birth',
    'registered_at',
    'barcode',
]

const verifyEmail = async (email) => {
    try {
        await api.get(`/email?value=${email}`);
    } catch (err) {
        throw new Error('Email already registered')
    }
}

const findKit = async (barcode) => {
    try {
        const result = await api.post('/kit', { barcode });
        if (!result.data?.success) throw new Error('Error')
    } catch (err) {
        throw new Error('Barcode not found or already registered. Please try again.')
    }
}

const validations = {
    email: async (value) => {
        isInvalidEmail(value)
    },
    barcode: async (value) => {
        await findKit(value);
    },
    password: (value) => {
        isInvalidPw(value)
    },
    other: (values) => {
        if (values.password !== values.repeat_password) throw new Error('Passwords must match')
    }
}

const Fields0 = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "barcode";
    box-sizing: border-box;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0,0,0,.2);
`;


const Fields1 = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "first_name last_name"
        "phone phone"
        "email email"
        "password repeat_password";
    box-sizing: border-box;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0,0,0,.2);

    @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr) !important;
        grid-template-areas: 
            "first_name"
            "last_name"
            "phone"
            "email"
            "password"
            "repeat_password" !important;
    }
`;

const Fields2 = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "date_of_birth date_of_birth"
        "registered_at registered_at";
    box-sizing: border-box;
`;

export const KitRegistration = () => {

    let thisFields = Object.values(accountInformation);
    thisFields = [thisFields.slice(0, 1), thisFields.slice(1, 7), thisFields.slice(7)]

    const values = useSelector((state) => state.registration);
    const navigate = useNavigate();

    const submit = async () => {
        try {
            const data = {
                ...values,
                date_of_birth: values.date_of_birth,
                registered_at: values.registered_at,
                barcode: values.barcode.toString()
            };


            const link = '/hundred/register';
            await api.post(link, data);
            navigate('/registration/success')
        } catch (err) {
            throw new Error(err.message);
        }
    }

    return <div style={{ margin: 'auto '}}>
        <RegistrationForm
            title='Fill the form below to register your kit'
            Fields={[Fields0, Fields1, Fields2]}
            fields={thisFields}
            requiredFields={requiredFields}
            submitAction={submit}
            validations={validations}
        />
    </div>
    
}

