import styled from "styled-components";
import Select from 'react-dropdown-select';
import loadingIcon from '../images/3-dots-move.svg';
import { forwardRef, useState } from "react";
import eyeButton from '../images/eye.svg';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './phone-number.css';
import { Controller } from "react-hook-form"


export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${props => props.breakpoint || '762px'}) {
    flex-direction: column;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Title = styled.div`
  margin: 0px;
  font-size: 40px;
  color: gray;
  font-family: 'Satoshi-Bold';

  @media (max-width: 762px) {
    font-size: 25px;
    font-family: 'Satoshi-Bold';
  }
`;

const LoadingIcon = styled.img`
  height: 25px;
  width: 25px;
  margin-left: 40px;
  margin-right: 40px;
`;

export const LinkTertiary = styled(Link)`
  margin-left: 30px;
  cursor: pointer;
  font-family: 'Satoshi-Bold';
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.013em;
  text-decoration: none;
  color: #4F59F0;
  white-space: nowrap;

  @media (max-width: 762px) {
    font-size: 17px;
  }
`;

const PrimaryButton = styled.div`
  border-radius: 28px;
  background: #4b4a4a;
  color: white;
  font-family: 'Satoshi-Bold';
  padding: 13px 26px;
  box-sizing: border-box;
  width: min-content;
  font-size: 20px;
  justify-content: center;
  text-align-vertical: center;
  cursor: pointer;
  pointer-events: ${props => props.disabled || props.loading ? 'none' : 'initial'};
  opacity: ${props => props.disabled || props.loading ? '0.5' : 'initial'};

  @media (max-width: 762px) {
    font-size: 17px;
  }
`;

export const Button = ({ className, loading, disabled, children, Component = PrimaryButton, ...props }) => {

  return <Component className={className} loading={loading} disabled={disabled} {...props}>
    {loading ? <LoadingIcon src={loadingIcon} /> : children}
  </Component>

}
