export const accountInformation = {
    kit_barcode: {
        label: 'What is your kit number',
        id: 'barcode',
        placeholder: 'Barcode',
        nextKey: 'first_name',
    },
    first_name: {
        label: 'First Name',
        id: 'first_name',
        placeholder: 'John',
        nextKey: 'last_name',
    },
    last_name: {
        label: 'Last Name',
        id: 'last_name',
        placeholder: 'Doe',
        nextKey: 'phone',
    },
    phone: {
        label: 'Phone Number (Optional)',
        id: 'phone',
        type: 'phone',
        placeholder: '000-000-000',
        nextKey: 'email',
    },
    email: {
        label: 'Email',
        id: 'email',
        placeholder: 'johndoe@mail.com',
        nextKey: 'password',
    },
    password: { 
        label: 'Your Password',
        type: 'password',
        id: 'password',
        placeholder: '•••••••••••••••••',
        nextKey: 'repeat_password',
    },
    repeat_password: { 
        label: 'Repeat your Password',
        type: 'password',
        id: 'repeat_password',
        placeholder: '•••••••••••••••••',
        nextKey: 'date_of_birth',
    },
    date_of_birth: {
        label: `What's your date of birth?`,
        id: 'date_of_birth',
        type: 'date',
        nextKey: 'registered_at',
    },
    registered_at: {
        label: 'When was your sample taken?',
        id: 'registered_at',
        type: 'date',
    }
}