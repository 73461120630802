/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import styled from 'styled-components';
import { Column } from "./components/defaults";
import GlobalStyle from "./components/globals";
import { KitSuccess } from "./pages/success";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

import { 
  Route, 
  BrowserRouter as Router, 
  Routes, 
  useLocation
} from 'react-router-dom';
import { KitRegistration } from "./pages/kit-registration";


const DashboardContainer = styled(Column)`
  position: relative;
  min-height: 100vh;
`;

const UseScroll = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  return <div />

}

function App() {

  return <DashboardContainer style={{ zIndex: 1, position: 'relative'}}>
    <GlobalStyle />
    <NotificationContainer />
    <Router>
      <UseScroll />
      <Routes>
        <Route path="/" element={<KitRegistration />} />
        <Route path="/registration/success" element={<KitSuccess />} />
      </Routes>
    </Router>
  </DashboardContainer>
  
}

export default App;
